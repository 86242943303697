import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ButtonMailto from './MailComponent';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/IWLogo.png';

export default function NavigationBar() {
    return (
        <Box sx={{ flexGrow: 1, width: "auto" }}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    {/* <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <Box
                        component="img"
                        sx={{ height: 40 }}
                        alt="Logo"
                        src={logo}
                        style={{ paddingRight: "10px", paddingBottom: "8px" }}
                    />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

                    </Typography>
                    <ButtonMailto label={"Contact Us"} mailto={'insurancewatcher@outlook.com'} />
                </Toolbar>
            </AppBar>
        </Box>
    );
}