import { createTheme } from '@mui/material/styles';

export default createTheme({
    palette: {
        primary: {
            main: "#122d42",
        },
        secondary: {
            main: "#3dd2cc",
        },
    },
});

